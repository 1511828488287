<template>
  <div class="online-store-detail">
    <el-table :data="tableData" style="width: 100%; flex: 1;margin-top: 20px" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
              class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
      <el-table-column prop="name" label="考试内容" align="left"></el-table-column>
      <el-table-column label="学生提交答案"  align="center">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="toDetail(scope.row.stu_answer)">查看详情</el-link>
        </template>
      </el-table-column>
      <!--   得分只在教师端考试中心-考试数据、实训数据显示   -->
      <el-table-column :label="`得分(${stuTotalScore}分)`" align="center" >
        <template slot-scope="scope">
          <span>{{scope.row.stu_score}}</span>
        </template>
      </el-table-column>
      <!--   操作只在教师端考试中心-考试数据、显示   -->
      <el-table-column label="操作" align="center" v-if="$route.name === 'examCenterExamDataStoreDecorate'">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="scoringDetails(scope.row)">评分详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="`评分详情${dialogTitle}`"
               :visible.sync="scoreDetailDialog" width="830px" custom-class="green-dialog goods-score-dialog">
      <div class="upload-file-content">
        <el-table :data="goodsScoreList" class="customTable goods-text-table" style="width: 100%; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                  :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
          <el-table-column prop="nickname" label="评委名称" align="center"></el-table-column>
          <el-table-column prop="score" label="分数" align="center"></el-table-column>
          <el-table-column prop="update_time" label="评分时间" align="center"></el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 20px;text-align: center"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="scoreCurrentChange">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {teacheropTeacherScoreDetail} from '@/utils/apis'
export default {
  name: "CustomPageDetail",
  props:['detailData'],
  data(){
    return {
      tableData:[],
      scoreDetailDialog:false,
      goodsScoreList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      stuTotalScore:0,
      dialogTitle:'',
    }
  },
  mounted() {
    this.tableData = this.detailData.list;
    if(this.tableData.length){
      this.stuTotalScore = this.tableData[0].stu_score
    }
  },
  methods:{
    toDetail(url){
      window.open(url, '_blank')
    },
    // 评分详情
    scoringDetails(row){
      let params = {
        exam_id:this.$route.query.examId,
        student_id:this.$route.query.id,
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      if(this.detailData.module_id === 12){
        params.type = 5
      } else if(this.detailData.module_id === 13){
        params.type = 6
      } else {
        params.type = 7
      }
      teacheropTeacherScoreDetail(params).then((res)=>{
        this.goodsScoreList = res.data.list;
        this.listPages.total = res.data.total;
        switch (res.data.give_type){
          case 1:
            this.dialogTitle = '（将学生平均分配给教师，最终分数为该教师评分的分数）';
            break;
          case 2:
            this.dialogTitle = '（将学生平均分配给评委，最终分数为该评委评分的分数）';
            break;
          case 3:
            this.dialogTitle = '（选择评分评委同时对学生评分，取平均分做该学生的分数）';
            break;
          case 4:
            this.dialogTitle = '（选择评分评委同时对学生评分，去掉最高分和最低分，取剩下分数的平均分做该学生的分数）';
            break;
        }
        if(!this.scoreDetailDialog){
          this.scoreDetailDialog = true
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    scoreCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.scoringDetails()
    }
  }
}
</script>

<style scoped lang="scss">
.online-store-detail{
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>